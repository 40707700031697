<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card v-if="editedItem">
        <v-card-title>
          <span class="text-h5">{{ $t('booking.scheduleForm.title', locale).concat(` ${editedItem.Alias}`) }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  :label="$t('booking.scheduleForm.alias', locale)"
                  v-model="editedItem.Alias"
                  outlined
                  hide-details
                />
              </v-col>
              <v-col
                cols="12"
                sm="1"
                md="1"
              >
                <v-checkbox v-model="noEndDate" />
              </v-col>
              <v-col
                cols="12"
                sm="11"
                md="11"
                v-if="noEndDate" 
                style="padding-top: 35px;"
              >
                {{ $t('common.noEndDate', locale) }}
              </v-col>
              <v-col
                cols="12"
                sm="11"
                md="11"
                style="padding-top: 15px;padding-bottom: 0;"
                v-else
              >
                <custom-datepicker
                  v-model="endDate"
                  :label="'common.endDate'"
                />
              </v-col>

              <v-col
                cols="12"
                sm="12"
                md="12"
                style="padding: 0 25px 10px 0;"
              >
                <small>* editar la fecha fin no modifica los eventos ya generados</small>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
                style="background-color: #CCCCCC;border-radius: 5px;"
              >
                <v-switch
                  v-model="editedItem.IsCustomLimit"
                  :label="$t('booking.scheduleForm.customLimit', locale)"
                  hide-details
                  style="margin-top: 0;"
                />
                <seats-limit
                  v-if="editedItem.IsCustomLimit"
                  :editedItem="editedItem"
                  :locale="locale"
                  :onChange="handleChangeSeatsLimit"
                />
                <v-radio-group 
                  v-model="editedItem.UpdateEvents"
                  hide-details
                >
                  <v-radio
                    key="update"
                    label="Actualizar/eliminar los eventos existentes"
                    :value="true"
                  />
                  <v-radio
                    key="noUpdate"
                    label="NO actualizar/eliminar los eventos existentes"
                    :value="false"
                  />
                </v-radio-group>
              </v-col>
              <!--v-col
                cols="12"
                sm="12"
                md="12"
              >3
                <custom-text-field-number
                  v-model="editedItem.BookingLimit"
                  :label="$t('booking.activities.bookingLimit', locale).concat(' (2)')"
                />
                <small>L�mite total de reservas de la actividad.</small>
                <custom-text-field-number
                  v-model="editedItem.Seats"
                  :label="$t('booking.activities.seats', locale).concat(' (10)')"
                  style="margin-top:10px"
                />
                <small>L�mite total de plazas de la actividad.</small>
                <custom-text-field-number
                  v-model="editedItem.SeatsByBooking"
                  :label="$t('booking.activities.seatsByBooking', locale).concat(' (5)')"
                  style="margin-top:10px"
                />
                <small>L�mite de plazas por reserva.</small>

                <v-radio-group 
                  v-model="editedItem.UpdateEvents"
                  hide-details
                >
                  <v-radio
                    key="update"
                    label="Actualizar/eliminar los eventos existentes"
                    :value="true"
                  />
                  <v-radio
                    key="noUpdate"
                    label="NO actualizar/eliminar los eventos existentes"
                    :value="false"
                  />
                </v-radio-group>
              </v-col-->
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <delete-item
            :id="sheduleID"
            :onDeleteItem="handleDeleteSchedule"
            :label="$t('common.delete', locale)"
          />
          <v-btn
            @click="handleClose"
          >
            
            {{ $t('common.cancel', locale) }}
          </v-btn>
          <!--v-btn
            color="red"
            dark
            @click="handleDeleteSchedule"
          >
            {{ $t('common.delete', locale) }}
          </v-btn-->
          <v-btn
            color="primary"
            @click="handleSaveSchedule"
          >
            {{ $t('common.save', locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import api from '@/services/api'
import DeleteItem from '@/components/DeleteItem'
//import CustomTextFieldNumber from '@/components/CustomTextFieldNumber'
import SeatsLimit from '@/components/seatsLimit/Index'
import CustomDatepicker from '@/components/CustomDatepicker'
export default {
  components: {
    DeleteItem,
  //  CustomTextFieldNumber,
    SeatsLimit,
    CustomDatepicker,
  },
  props: {
    scheduleID: {
      type: String,
      default: null,
    },
    onClose: {
      type: Function,
      required: true,
    },
    locale: {
      type: String,
      default: 'es',
    },
  },
  data: () => ({
    dialog: false,
    editedItem: null,
    noEndDate: null,
    endDate: new Date().toISOString().split('T').shift(),
  }),
  watch: {
    scheduleID () {
      this.prepareDialog()
    },
  },
  methods: {
    prepareDialog () {
      if (this.scheduleID === null) {
        this.dialog = false
        return
      }
      this.handleGetSchedule ()
    },
    handleGetSchedule () {
      this.editedItem = null
      api.getItem ('booking', `v1/private/schedules/`, this.scheduleID)
        .then(response => {
          if (!response) return
          this.noEndDate = !response.EndDate ? true : false
          if (!this.noEndDate)this.endDate = response.EndDate.split('T').shift()
          this.editedItem = {
              Alias:response.Alias,
              Seats: response.Seats,
              SeatsByBooking: response.SeatsByBooking,
              ResourceID: response.ResourceID,
              BookingLimit: response.BookingLimit,
              IsCustomLimit: response.IsCustomLimit,
              SeatsType: response.SeatsType ? response.SeatsType : 'byCapacity',
              UpdateEvents: true,
            }
          this.dialog = true
        })
    },
    handleChangeSeatsLimit (v) {
      this.editedItem.SeatsType = v.SeatsType
      this.editedItem.Seats = v.Seats
      this.editedItem.SeatsByBooking = v.SeatsByBooking
      this.editedItem.BookingLimit = v.BookingLimit
      this.editedItem.ResourceID = v.ResourceID
    },
    handleSaveSchedule () {
      this.editedItem.EndDate = this.noEndDate ? null : this.endDate
      api.updateItem ('booking', `v1/private/schedules/`, this.scheduleID.concat('/simple'), this.editedItem)
        .then(response => {
          if (response.data.status === 'OK') this.handleClose()
          else alert('Error')
        })
        .catch (() => {
          alert( 'Error' )
        })
    },
    handleDeleteSchedule () {
      api.deleteItem ('booking', `v1/private/schedules/`, this.scheduleID.concat(this.editedItem.UpdateEvents ? '?updateEvent=true' : ''))
        .then(response => {
          if (response.data.status === 'OK') this.handleClose()
          else alert('Error')
        })
        .catch (() => {
          alert( 'Error' )
        })
    },
    handleClose () {
      this.editedItem = null
      this.onClose(null)
    },
  },
}
</script>

